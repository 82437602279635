<template>
    <main class="p-project">
        <section class="top">
            <section class="project-intro">
                <router-link :to="$t('nav.projects.uri')" class="cta-backtoprojects">
                    <svg height="22" viewBox="0 0 31 22" width="31" xmlns="http://www.w3.org/2000/svg"><path d="m200.75 9h3.5v23.9568345l8.75-8.8489208v4.5323741l-10.5 10.3597122-10.5-10.1438849v-4.7482014l8.75 8.8489208z" fill="none" stroke="#d8d8d8" stroke-width=".7" transform="matrix(0 -1 1 0 -8.5 213.5)"/></svg>
                    {{ $t('project.backToProjects') }}
                </router-link>

                <h1 v-if="project">{{ project.title }}</h1>

                <div class="infos" v-if="project">
                    <span>{{ $t('project.project') }}<span class="lowercase">{{ $t('projects.categories.' + project.category) }}</span></span>
                    <span>{{ $t('projects.realised') }}<span class="lowercase">{{ project.realisationDate }}</span></span>
                    <span>{{ project.realisationPlace }}</span>
                </div>
            </section>

            <section class="images" v-if="project">
                <div class="image-container">
                    <img v-if="project.fullwidthImages[0]" :src="project.fullwidthImages[0]" alt="" class="fullwidth" />
                </div>

                <div class="container" v-if="imagesFirstHalf">
                    <div class="columns">
                        <div><img v-if="imagesFirstHalf[0]" :src="imagesFirstHalf[0]" alt="" /></div>
                        <div><img v-if="imagesFirstHalf[1]" :src="imagesFirstHalf[1]" alt="" /></div>
                    </div>
                    <img v-if="imagesFirstHalf[2]" :src="imagesFirstHalf[2]" alt="" />
                </div>

                <div class="image-container">
                    <img v-if="project.fullwidthImages[1]" :src="project.fullwidthImages[1]" alt="" class="fullwidth" />
                </div>

                <div class="container" v-if="imagesSecondHalf">
                    <img v-if="imagesSecondHalf[0]" :src="imagesSecondHalf[0]" alt="" />
                    <div class="columns">
                        <div><img v-if="imagesSecondHalf[1]" :src="imagesSecondHalf[1]" alt="" /></div>
                        <div><img v-if="imagesSecondHalf[2]" :src="imagesSecondHalf[2]" alt="" /></div>
                    </div>
                </div>
            </section>
        </section>

        <section class="next" v-if="project">
            <div v-if="project.next.imageUrl" alt="" class="fullwidth" :style="{ backgroundImage: 'url(' + project.next.imageUrl + ')' }">></div>

            <h1 v-if="project" >{{ $t('project.next') }}</h1>
        </section>
    </main>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default {
    name: 'Project',

    data() {
        return {
            project: null,
            hasNavigated: false
        }
    },

    computed: {
        imagesFirstHalf() {
            return this.project ? this.project.images.slice(0, 3) : null;
        },

        imagesSecondHalf() {
            return this.project ? this.project.images.slice(3) : null;
        },
    },

    created() {
        this.$craft.getContentBySlug(this.$route.params.slug, 'projects')
            .then(res => {
                this.project = res.data;
                if (res.data.seo) {
                    this.$store.commit('SET_SEO', res.data.seo);
                }
            })
            .then(() => {
                ScrollTrigger.refresh(true);
                this.initAnimations();
            })
            .then(() => {
                this.$store.commit('SET_OTHER_LOCALES_URLS', this.project.otherLocalesUrls);
                this.$store.commit('SET_DATA_READY', true);
            });
    },

    updated() {
        ScrollTrigger.refresh(true);
    },

    methods: {
        initAnimations() {
            gsap.utils.toArray('.image-container img').forEach((fullwidthImage) => {
                gsap.fromTo(fullwidthImage, {
                    y: -100,
                }, {
                    y: 0,
                    ease: 'none',
                    scrollTrigger: {
                        trigger: fullwidthImage,
                        start: 'top center',
                        end: '+=100%',
                        scrub: 0.8,
                    },
                });
            });

            ScrollTrigger.refresh(true);

            gsap.fromTo('.next', {
                opacity:0,
            }, {
                opacity:1,
                scrollTrigger: {
                    trigger: '.images',
                    start: '95% 70%',
                    end: 'bottom top',
                    scrub: 0.2,
                    once:true,
                },
                onComplete: () => {
                    if (!this.hasNavigated) this.goToNextProject();
                    ScrollTrigger.refresh();
                }
            });

            ScrollTrigger.refresh();
        },


        goToNextProject() {
            this.hasNavigated = true;
            this.$router.push(this.project.next.uri);
        }
    }
}
</script>
